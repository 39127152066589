import React, { useState, useEffect } from "react";
import axios from "axios";

function ReadQR() {
  const [files, setFiles] = useState([]); // Store list of QR code files
  const [selectedFile, setSelectedFile] = useState(""); // Store the selected file
  const [qrCodeUrl, setQrCodeUrl] = useState(""); // Store the URL of the selected QR code

  useEffect(() => {
    // Fetch the list of QR code files from the server
    axios
      .get("https://irsindia.in/php/list_qr_files.php") // Update this to your actual back-end URL
      .then((response) => {
        setFiles(response.data); // Store the list of files
      })
      .catch((error) => {
        console.error("Error fetching files:", error);
      });
  }, []);

  // Handle dropdown selection change
  const handleFileChange = (event) => {
    const selectedFileName = event.target.value;
    setSelectedFile(selectedFileName);
    // Set the URL of the selected QR code
    setQrCodeUrl(`https://irsindia.in/qr_codes/${selectedFileName}`);
  };

  return (
    <div className="leadsList-container">
      <h2>Select a QR Code</h2>
      <select value={selectedFile} onChange={handleFileChange}>
        <option value="">--Select a QR Code--</option>
        {files.map((file, index) => (
          <option key={index} value={file}>
            {file}
          </option>
        ))}
      </select>

      {qrCodeUrl && (
        <div>
          <h3>QR Code:</h3>
          <img src={qrCodeUrl} alt="Selected QR Code" style={{ marginTop: "20px", width: "100%"}} />
        </div>
      )}
    </div>
  );
}

export default ReadQR;
