import axiosInstance from "../utils/axiosInstance";


export const getLeads = () => {
  return axiosInstance.get("/app/leads/v1/getLeads");
};
export const getLeadsById = (leadId) => {
  return axiosInstance.get(`/app/leads/v1/getLeads/${leadId}`);
};


export const submitInspection = async (leadId) => {
  try {
    const response = await axiosInstance.put(`/app/leads/v1/submitInspection/${leadId}`);
    return response;
  } catch (error) {
    throw error;
  }
};


// export const uploadMedia = ({ regNo, leadId, docType }) => {
//   return axiosInstance.post(
//     `/s3upload/v1/uploadImage/${regNo}??leadId=${leadId}&docType=${docType}`,
//     {

//     }
//   );
// };

export const verifyDetails = ({ leadId, isVerified, remarks }) => {
  return axiosInstance.put(
    `/app/leads/v1/verifyLeadDetails/${leadId}?isVerified=${isVerified}&remarks=${remarks}`
  );
};

export const getUploadedMediaById = (leadId) => {
  return axiosInstance.get(`/s3upload/v1/getUploadedImagesList/${leadId}`);
};

export const uploadMedia = async ({ regNo, leadId, docType, file }) => {
  const formData = new FormData();
  console.log('file',file);
  formData.append("file", file);

  return await axiosInstance.post(
    `/s3upload/v1/uploadImage/${regNo}?leadId=${leadId}&docType=${docType}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
