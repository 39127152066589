import React from "react";
import "./CircularLoader.css";

const CircularLoader = () => {
  return (
    <div className="loader">
      <div className="loader-inner"></div>
    </div>
  );
};

export default CircularLoader;
