import React, { useState } from "react";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router-dom";

import "./LoginPage.css";
import { loginApi } from "../../api/auth";
import { saveToLocal } from "../../utils/localStorage";
import { toast } from "react-toastify";

const LoginPage = () => {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    loginApi({ mobile: username, password })
      .then((res) => {
        if (!res.data?.error) {
          login();
          saveToLocal("token", res.data?.data?.auth_token);
          navigate("/leadsList");
        } else {
          toast.error("Login failed", res.data.messages);
          console.log("Login failed", res.data.message);
        }
      })
      .catch((err) => {
        console.log("error", err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

   
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="input-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            className="input-border"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder="Enter username"
            maxLength={10}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password:</label>
          <div className="password-wrapper input-border">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter password"
            />
            <span
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "🙈" : "👁️"}
            </span>
          </div>
        </div>
        <button className="custom-button" type="submit">
          {isLoading ? "Logging..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
