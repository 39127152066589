import React, { useEffect, useState } from "react";
import { getLeadsById, verifyDetails } from "../../api/appLeads";
import "./LeadDetails.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import CircularLoader from "../CircularLoader";

function LeadDetails() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const leadId = urlParams.get("leadId");
  const [leadDetails, setLeadDetails] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleVerification = (isVerified) => {
    setIsLoading(true);
    verifyDetails({ leadId, isVerified, remarks })
      .then((res) => {
        
        let regData = leadDetails.find(
          (item) => item.display_name === "Vehicle Registration No"
        );
        let vehicleTypeData = leadDetails.find(
          (item) => item.display_name === "Vehicle Type"
        );
        navigate(`/mediaListPage?leadId=${leadId}&regNo=${regData.value}&vehicleType=${vehicleTypeData.value}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getLeadsById(leadId)
      .then((res) => {
        setLeadDetails(res.data?.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="leadsDetails-main-container">
      <h5 className="leads-heading">Vehicle</h5>
      <div className="leadsDetails-container">
        {isLoading && <CircularLoader />}
        {!isLoading && (
          <div className="leadsDetails-card">
            {leadDetails?.map((item) => (
              <p key={item.display_name}>
                <span>{item.display_name}</span>: {item.value}
              </p>
            ))}
          </div>
        )}
      </div>
      <button
        className="custom-button"
        onClick={() => {
          handleVerification(true);
        }}
      >
        Confirm
      </button>
      <div style={{ maxWidth: "500px", width: "300px", margin: "1.5rem auto" }}>
        <div className="input-group">
          <label htmlFor="username">Modification Required?</label>
          <input
            type="text"
            className="input-border"
            id="username"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Reason"
          />
        </div>
        <button
          className="custom-button"
          style={{ background: !remarks ? "#ff000050" : "red" }}
          disabled={!remarks}
          onClick={() => {
            handleVerification(false);
          }}
        >
          Modification Request
        </button>
      </div>
    </div>
  );
}

export default LeadDetails;
