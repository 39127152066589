export const saveToLocal = (key, value) => {
  if (typeof window === "undefined") return;
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocal = (key) => {
  if (typeof window === "undefined") return;
  localStorage.removeItem(key);
};

export const getFromLocal = (key) => {
  if (typeof window === "undefined") return null;
  const data = localStorage.getItem(key);
  if (data) return JSON.parse(data);
  return undefined;
};
