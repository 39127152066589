import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import RotateIcon from "../../assets/Rotate.svg";
import WebcamButton from "../../assets/WebcamButton.svg";
import "./Capture.css";
import { uploadMedia } from "../../api/appLeads";
import { toast } from "react-toastify";
import CircularLoader from "../CircularLoader";

const Capture = ({
  captureType = "photo",
  regNo = "",
  leadId = "",
  docType = "",
  onClose = () => {},
}) => {
  const webcamRef = useRef(null);
  const [isCapturing, setIsCapturing] = useState(true);
  const [isPermission, setIsPermission] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isWebcamLoading, setIsWebcamLoading] = useState(true);
  const [facingMode, setFacingMode] = useState(docType === 'SELFI' ? "user" : "environment");
  const [imgSrc, setImgSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  
  useEffect(() => {
    setIsRecording(docType === "Video");
  }, [docType]);

  const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: facingMode,
  };

  const toggleFacingMode = () => {
    setFacingMode(prev => prev === "user" ? "environment" : "user");
  };

  const getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        error => reject(error)
      );
    });
  };

  const addWatermark = (imageSrc, position, timestamp) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        ctx.font = "bold 20px Arial";
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.shadowColor = "black";
        ctx.shadowBlur = 7;

        const text1 = `Lat: ${position.lat}, Long: ${position.long}`;
        const text2 = timestamp;

        const x = canvas.width / 2;
        const y1 = canvas.height - 50;
        const y2 = canvas.height - 25;

        ctx.fillText(text1, x, y2);
        ctx.fillText(text2, x, y1);

        const watermarkedImage = canvas.toDataURL("image/jpeg");
        resolve(watermarkedImage);
      };
    });
  };

  const startVideoRecording = async () => {
    setVideoSrc(null);
    setIsRecording(true);
  
    const position = await getPosition();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
  
    const stream = webcamRef.current.stream;
    const videoTrack = stream.getVideoTracks()[0];
    const videoSettings = videoTrack.getSettings();
  
    canvas.width = videoSettings.width;
    canvas.height = videoSettings.height;
  
    const recorderStream = canvas.captureStream();
    const recorder = new MediaRecorder(recorderStream);
    setMediaRecorder(recorder);
  
    const chunks = [];
    const startTime = Date.now();
    recorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
  
    recorder.onstop = () => {
      const videoBlob = new Blob(chunks, { type: "video/mp4" });
      setVideoSrc(URL.createObjectURL(videoBlob));
      setIsRecording(false);
    };
  
    recorder.start();
  
    const drawFrame = () => {
      ctx.drawImage(webcamRef.current.video, 0, 0, canvas.width, canvas.height);
  
      ctx.font = "bold 20px Arial";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.shadowColor = "black";
      ctx.shadowBlur = 7;
  
      const timestamp = new Date().toLocaleString("hi");
      const text1 = `Lat: ${position.lat}, Long: ${position.long}`;
      const text2 = timestamp;
  
      const x = canvas.width / 2;
      const y1 = canvas.height - 50;
      const y2 = canvas.height - 25;
  
      ctx.fillText(text1, x, y2);
      ctx.fillText(text2, x, y1);
  
      if (recorder.state !== "inactive") {
        requestAnimationFrame(drawFrame);
      }
    };
  
    drawFrame();
  };
  

  const stopVideoRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  const toggleCapturing = async () => {
    setImgSrc("");
    if (isCapturing) {
      if (captureType === "photo") {
        try {
          const position = await getPosition(); // Request location
          const imageSrc = webcamRef.current.getScreenshot();
          const timestamp = new Date().toLocaleString("hi");
          const watermarkedImage = await addWatermark(imageSrc, position, timestamp);
          setImgSrc(watermarkedImage);
        } catch (error) {
          console.error("Error capturing photo:", error);
          toast.error("Failed to capture location. Please ensure location services are enabled.");
        }
      } else {
        startVideoRecording();
      }
    } else {
      if (isRecording) {
        stopVideoRecording();
      }
    }
    setIsCapturing(!isCapturing);
  };
  

  const handleSave = async (file) => {
    setIsLoading(true);
    try {
      await uploadMedia({ regNo, leadId, docType, file });
      toast.success("Media saved successfully.");
      onClose();
    } catch (error) {
      toast.error("Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const saveCapturedMedia = async () => {
    if (!imgSrc && !videoSrc) {
      return toast.error("Please capture media before saving it.", { toastId: "capturePhotoId" });
    }

    setIsLoading(true);
    const blob = imgSrc
      ? await fetch(imgSrc).then(res => res.blob())
      : await fetch(videoSrc).then(res => res.blob());
    const file = imgSrc
      ? new File([blob], "image.jpeg", { type: "image/jpeg" })
      : new File([blob], "video.mp4", { type: "video/mp4" });

    handleSave(file);
  };

  return (
    <div style={{ position: "relative", maxHeight: "calc(100% - 50px)", width: "100%", maxWidth: "720px", margin: "auto" }}>
      {isWebcamLoading && (
        <div style={{ width: "100%", height: "100%", margin: "auto", display: "flex", justifyContent: "center" }}>
          <CircularLoader />
        </div>
      )}

      {!isPermission && (
        <div style={{ color: "red", width: "100%", background: "white", padding: "2rem 1rem", borderRadius: ".5rem", margin: "auto", textAlign: "center", position: "absolute", top: "50%", left: "50%", marginTop: "4rem", transform: "translateX(-50%)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "2rem" }} onClick={() => onClose()}>
          <p>Camera permission denied! Please allow Camera Access to click a picture</p>
          <button className="webcamButton">Got it</button>
        </div>
      )}

      {isPermission && (
        <Webcam
          audio={false}
          height={"100%"}
          ref={webcamRef}
          screenshotFormat="image/png"
          width={"100%"}
          onUserMedia={() => setIsWebcamLoading(false)}
          videoConstraints={videoConstraints}
        />
      )}

      {!isWebcamLoading && (
        <img style={{ display: "block", position: "absolute", top: "0", right: "0" }} src={RotateIcon} width={50} alt="Rotate" onClick={toggleFacingMode} />
      )}

      {!isCapturing && imgSrc && (
        <img style={{ margin: "auto", position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)" }} width={"100%"} src={imgSrc} alt="imgSrc" />
      )}

      {!isCapturing && videoSrc && (
        <video src={videoSrc} controls={true} style={{ margin: "auto", position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)" }} autoPlay={true} width={"100%"} />
      )}

      {isPermission && (
        <div className="webcamFooterContainer">
          <div className="innerContainer">
            <button onClick={onClose} className={"webcamButton"}>Cancel</button>
            <div style={{ position: "relative" }}>
              <img
                style={{ display: "block", cursor: "pointer" }}
                role="button"
                src={WebcamButton}
                height={60}
                width={60}
                alt="WebcamButton"
                onClick={toggleCapturing}
              />
              {!isCapturing && !isRecording && (
                <p style={{ position: "absolute", color: "black", margin: "auto", top: "50%", left: "50%", transform: "translate(-50%,-50%)", fontSize: ".8rem", cursor: "pointer" }}>Retake</p>
              )}
              {isRecording && !isCapturing && (
                <p style={{ position: "absolute", textAlign: "center", color: "black", margin: "auto", top: "52%", left: "50%", transform: "translate(-50%,-50%)", fontSize: ".8rem", cursor: "pointer" }} onClick={stopVideoRecording}>
                  <img width={30} height={30} src="https://cdn-icons-png.flaticon.com/512/11762/11762491.png" alt="Stop" />
                </p>
              )}
            </div>
            <button onClick={saveCapturedMedia} className={"webcamButton"}>
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Capture;
