import Axios from "axios";

import { getFromLocal } from "./localStorage";
const baseURL = "https://api.irsindia.in/";
const axiosInstance = Axios.create({
  //   baseURL: process.env.REACT_APP_API_URL,
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const request = config;
    request.headers = {
      "Content-Type": "application/json",
      ...config.headers,
      Authorization: `${
        getFromLocal("token") ? getFromLocal("token") : ""
      }`,
    };
    return request;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance;
