import React, { useState, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { toJpeg } from "html-to-image";
import { toast } from "react-toastify";
import axios from "axios"; // Add axios for API calls
import "./QRCode.css";

function QRCodeGenerator() {
  const [name, setName] = useState("");
  const qrCodeRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please enter a name!");
      return;
    }

    try {
      const qrElement = qrCodeRef.current;
      const dataUrl = await toJpeg(qrElement, { quality: 0.95 });

      // Send base64 image to the server
      /*
      const response = await axios.post("/api/save-qr-code", {
        name,
        qrCode: dataUrl,
      });
      */
      const response = await axios.post("https://irsindia.in/php/save_qr_code.php", {
        name,
        qrCode: dataUrl,
    });
    

      if (response.status === 200) {
        toast.success("QR code saved successfully on the server!");
      } else {
        toast.error("Failed to save QR code.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error generating QR code.");
    }
  };

  return (
    <div className="leadsList-container">
    <div className="qr-code-generator">
      <h5>Generate QR Code</h5>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="fullName">Full Name:</label><br />
          <input
          className="mytxt"
            type="text"
            id="fullName"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>

      {/* Hidden QR Code for Image Generation */}
      <div style={{ marginTop: "20px" }}>
        <div ref={qrCodeRef} style={{ display: "inline-block" }}>
          <QRCodeCanvas value={name || "Default"} size={256} />
        </div>
      </div>
    </div>
    </div>
  );
}

export default QRCodeGenerator;
